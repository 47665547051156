<template>
	<layout-main :show-header="true">
		<div>
			<div class="m-4">
				<div class="flex justify-center md:justify-end mt-4 md:mt-0">
					<div class="flex justify-start items-center w-full">
						<p
							class="text-lg md:text-2xl md:w-auto tracking-tight text-left flex flex-col md:flex-row justify-start items-center"
						>
							<span class="text-left"
								>Season {{ season?.slug || 'Unknown' }}</span
							>
							<select
								v-model="viewingWeekId"
								class="rounded-lg text-teal-800 px-2 py-1 text-base bg-white"
								v-if="weeks"
								@change="changeWeek"
							>
								<option v-for="week of weeks" :value="week.id">
									Week
									{{
										week.isCurrent
											? `${week.week_number} (Current)`
											: week.week_number
									}}
								</option>
							</select>
							<template v-else>
								<fa icon="spinner" class="text-gray-500" spin />
							</template>
						</p>

						<p class="flex-grow text-right">
							<router-link
								v-if="
									entries &&
									entries.length > 0 &&
									availableEntries &&
									availableEntries > 0 &&
									currentWeek &&
									DateTime.now() >= currentWeek.open_at &&
									DateTime.now() < currentWeek.end_at
								"
								custom
								v-slot="{ navigate }"
								:to="{ name: 'create' }"
							>
								<button
									class="tracking-tight text-base bg-teal-800 text-white shadow px-4 py-1 md:py-2 rounded-lg enabled:hover:bg-teal-900 disabled:bg-gray-300 disabled:cursor-not-allowed"
									@click="navigate"
									:disabled="
										availableEntries === 0 ||
										createButtonDisabled
									"
								>
									Create Entry
								</button>
							</router-link>
						</p>
					</div>
				</div>
				<div
					class="grid grid-cols-2 text-center mt-4 gap-8 items-stretch"
				>
					<div
						class="p-4 flex justify-center items-center rounded-xl"
						:class="[
							entries == null || entries.length == 0
								? 'bg-teal-800/10'
								: '',
						]"
					>
						<template v-if="availableEntries === null">
							<fa
								icon="spinner"
								class="text-6xl text-gray-500"
								spin
							/>
						</template>
						<template v-else>
							<div>
								<p class="text-lg font-medium">
									Remaining Entries
								</p>
								<p class="text-base md:text-xl text-gray-600">
									{{ availableEntries }}
								</p>
								<p class="text-xs md:text-sm text-gray-500">
									Rank: {{ currentRank }}
								</p>
							</div>
						</template>
					</div>
					<div
						class="p-4 flex justify-center items-top rounded-xl"
						:class="[
							entries == null || entries.length == 0
								? 'bg-teal-800/10'
								: '',
						]"
					>
						<div v-if="currentWeek">
							<p class="text-lg font-medium">
								<span
									v-if="DateTime.now() < currentWeek.open_at"
								>
									Entries Open
								</span>

								<span
									v-else-if="
										DateTime.now() < currentWeek.end_at
									"
								>
									Week ends
								</span>
								<span
									v-else-if="
										DateTime.now() > currentWeek.end_at
									"
								>
									Week ended
								</span>
							</p>

							<p
								class="text-base md:text-xl tracking-tight font-normal text-gray-600"
							>
								<span
									v-if="DateTime.now() < currentWeek.open_at"
								>
									<timeago
										:datetime="
											currentWeek.open_at.toString()
										"
									/>
									<br />
									<span
										class="text-xs md:text-sm text-gray-500"
									>
										{{
											currentWeek.open_at.toLocaleString(
												DateTime.DATETIME_FULL,
											)
										}}
									</span>
								</span>

								<span
									v-else-if="
										DateTime.now() < currentWeek.end_at
									"
								>
									<timeago
										:datetime="
											currentWeek.end_at.toString()
										"
									/>
									<br />
									<span
										class="text-xs md:text-sm text-gray-500"
									>
										{{
											currentWeek.end_at.toLocaleString(
												DateTime.DATETIME_FULL,
											)
										}}
									</span>
								</span>
								<span
									v-else-if="
										DateTime.now() > currentWeek.end_at
									"
								>
									<timeago
										:datetime="
											currentWeek.end_at.toString()
										"
									/>
									<br />
									<span
										class="text-xs md:text-sm text-gray-500"
									>
										{{
											currentWeek.end_at.toLocaleString(
												DateTime.DATETIME_FULL,
											)
										}}
									</span>
								</span>
							</p>
						</div>
						<div v-else>
							<fa
								icon="spinner"
								class="text-6xl text-gray-500"
								spin
							/>
						</div>
					</div>
					<!-- 
							removing this, for now...

							<p class="text-xl font-medium flex justify-center">
							First Pick Bonus
							<span class="ml-2 group relative flex">
								<span class="text-teal-800 mr-1">
									<fa icon="fa-regular fa-circle-question" />
								</span>
								<span
									class="hidden group-hover:block text-sm font-normal absolute w-64 bg-white rounded shadow px-4 py-2 z-10 right-0"
								>
									<span class="font-bold"
										>First pick bonus.</span
									>
									Your first pick, each week, earns you a
									mileage bonus regardless of the outcome of
									your pick.
								</span>
							</span>
						</p>
						<p class="text-3xl">{{ thisWeekBonusMiles }} Miles</p>
						<p class="text-sm text-gray-500">
							Your Balance: {{ toFloat(currentBalance) }} Miles
						</p> -->
					<!-- </div> -->
				</div>
			</div>

			<div class="px-4 space-y-6 mb-8">
				<div v-if="entries?.length == 0" class="text-center">
					<p
						class="text-gray-400 font-light tracking-wide text-xl mt-12"
					>
						No entries for this week
					</p>
					<div class="relative mx-auto min-w-72 w-1/3">
						<img src="/img/unpicked.png" class="opacity-30" />
						<template v-if="currentWeek.isCurrent">
							<!-- <p class="my-4 text-gray-800"> -->
							<!-- 	Click below to make your first entry, and earn -->
							<!-- 	<span class="text-teal-800 font-medium" -->
							<!-- 		>{{ thisWeekBonusMiles }} miles!</span -->
							<!-- 	> -->
							<!-- </p> -->
							<div
								v-if="DateTime.now() >= currentWeek.open_at"
								class="absolute inset-0 flex justify-center items-center"
							>
								<router-link
									custom
									v-slot="{ navigate }"
									:to="{ name: 'create' }"
								>
									<button
										class="bg-teal-800 text-white shadow px-8 py-2 rounded-lg enabled:hover:bg-teal-900 disabled:bg-gray-400"
										@click="navigate"
										:disabled="createButtonDisabled"
									>
										Create Your First Entry
									</button>
								</router-link>
							</div>
						</template>
					</div>
				</div>

				<div>
					<div v-if="entries?.length > 0" class="flex mb-12">
						<div
							:class="[
								'w-1/2 flex justify-center items-center text-center p-2 rounded-s-lg cursor-pointer',
								view === 'entries'
									? 'bg-gray-300'
									: 'bg-gray-200',
							]"
							@click="view = 'entries'"
						>
							<div>View Entries</div>
						</div>
						<div
							:class="[
								'w-1/2 flex justify-center items-center text-center p-2 rounded-e-lg cursor-pointer',
								view === 'heroEntries'
									? 'bg-gray-300'
									: 'bg-gray-100',
							]"
							@click="view = 'heroEntries'"
						>
							<div>View Entry Heroes</div>
						</div>
					</div>
					<div v-if="view === 'entries'">
						<entry-summary
							v-for="(entry, x) in entries"
							:id="entry.id"
							:key="`parlay-result-${x}`"
							:entries="entry.entry_count"
							:heroList="filteredHeroes"
							:firstPickBonus="
								Number(entry.miles_earned?.toString())
							"
							:miles="Number(entry.miles_won?.toString())"
							:picks="entry.heroes || []"
							:expanded="entries?.length == 1"
							:isFinalized="
								!weeksById[entry.week_id] ||
								DateTime.now() > weeksById[entry.week_id].end_at
							"
							@reload="reloadEntries"
						/>
					</div>
					<div v-else-if="view === 'heroEntries'">
						<hero-summary :entries="entries" :season="season" />
					</div>
				</div>
			</div>
		</div>
	</layout-main>
</template>
<script lang="ts" setup>
import EntrySummary from '@/components/Entries/EntrySummary.vue'
import HeroSummary from '@/components/Entries/HeroSummary.vue'

import { usePickSixStore } from '@/stores/PickSixStore'
import { Entry, ListEntryResponse } from '@/types/Entry'
import { Season, SeasonResponse } from '@/types/Season'
import { ComputedRef, computed, onBeforeMount, ref } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useLogger } from '@/modules/log'
import { Week, WeeksArray } from '@/types/Week'
import { Rank, User } from '@/types/User'
import { DateTime } from 'ts-luxon'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const authStore = useAuthStore()
const log = useLogger()

const pickSixStore = usePickSixStore()

const season = ref<Season | null>(null)
const entries = ref<Entry[] | null>(null)
const user = ref<User | null>(null)
const viewingWeekId = ref<string>('')
const view = ref<string>('entries')

const currentWeekRank: ComputedRef<Rank | undefined> = computed(() => {
	if (!user.value) {
		return undefined
	}

	return user.value.rankings.find((rank: Rank) => {
		return rank.week_id === viewingWeekId.value
	})
})

const weeksById: ComputedRef<WeeksArray> = computed(() => {
	if (!season.value) {
		return {} as WeeksArray
	}

	return Object.values(season.value.weeks!).reduce((wks, wk) => {
		wks[wk.id] = wk
		return wks
	}, {} as WeeksArray)
})

const availableEntries: ComputedRef<number | null> = computed(() => {
	if (currentWeekRank.value === undefined) {
		if (user.value !== null) {
			return 0
		}
		return null
	} else {
		const { entries_allowed, total_entry_count } = currentWeekRank.value
		return entries_allowed - total_entry_count
	}
})

const currentBalance: ComputedRef<bigint> = computed(() => {
	return currentWeekRank.value?.miles_balance || BigInt(0)
})

const thisWeekBonusMiles: ComputedRef<bigint> = computed(() => {
	return (
		(currentWeekRank.value?.miles_balance || BigInt(0)) /
		BigInt(3) /
		BigInt(52)
	)
})

const currentRank: ComputedRef<number | string> = computed(() => {
	return Number(currentWeekRank.value?.rank) || 'Unranked'
})

const currentWeek: ComputedRef<Week | any> = computed(() => {
	if (season.value?.weeks == undefined) {
		return {}
	}

	if (season.value?.weeks.current == undefined) {
		return {}
	}

	const week = Object.values(season.value?.weeks!).find((week: Week) => {
		if (week.id === viewingWeekId.value) {
			return week
		}
	})
	viewingWeekId.value = week ? week.id : season.value?.weeks.current.id
	return week ? week : season.value?.weeks.current
})

async function loadUser(): Promise<User | null> {
	return authStore.getAccount().then((u) => {
		if (u) {
			user.value = u

			return u
		}

		return null
	})
}

const weeks: ComputedRef<Week[] | null> = computed(() => {
	if (season.value?.weeks == undefined) {
		return null
	}
	const weeks = Object.values(season.value?.weeks!).filter(
		(value) => value.open_at < DateTime.now(),
	)

	const nextWeek = Object.values(season.value?.weeks!)
		.filter((value) => value.open_at > DateTime.now())
		.reverse()
		.pop()

	if (nextWeek) {
		weeks.push(nextWeek as Week)
	}

	weeks.sort((a, b) => a!.week_number - b!.week_number)
	return weeks
})

onBeforeMount(async () => {
	loadUser()

	pickSixStore
		.currentSeason()
		.then((response: SeasonResponse): Season => {
			if (response.success) {
				season.value = response.season!
				return response.season!
			}
			throw new Error('Failed to load season')
		})
		.then(async (szn: Season): Promise<void> => {
			let week
			if (route.params.week_number) {
				week = Object.values(szn.weeks!).find((week: Week) => {
					return week.week_number === Number(route.params.week_number)
				})
			}
			if (week) {
				viewingWeekId.value = week.id
				return await loadEntries(szn.slug, week.id as string)
			} else {
				return await loadEntries(szn.slug, szn.weeks!.current.id)
			}
		})
})

const filteredHeroes = computed(() => {
	if (season.value == null) {
		return []
	}

	return season.value.weeks!.current!.heroes!
})

const createButtonDisabled = computed((): Boolean => {
	if (filteredHeroes.value == null) {
		return true
	}

	if (DateTime.now() >= currentWeek.value.end_at) {
		return true
	}
	const disabledHeroes = filteredHeroes.value.reduce((h, hero) => {
		if (
			hero.actual_points > 0 ||
			hero.projected_points === 0 ||
			hero.first_game_of_week === null ||
			DateTime.fromSQL(hero.first_game_of_week.toString()) <=
				DateTime.now()
		) {
			h[hero.id] = true
		}
		return h
	}, {})
	return (
		Object.keys(filteredHeroes.value).length -
			Object.keys(disabledHeroes).length <
		6
	)
})

async function loadEntries(seasonSlug: string, weekId: string) {
	await pickSixStore
		.loadEntries(seasonSlug, weekId)
		.then((response: ListEntryResponse) => {
			if (response.success) {
				entries.value = response.entries!
				return
			}
			throw new Error('Failed to load user entries')
		})
		.catch((err) => {
			log.error('ViewEntries: failed to load user data', {
				err: err.message,
				season: season.value,
				entries: entries.value,
			})
		})
}

async function changeWeek() {
	if (weeksById.value[viewingWeekId.value].isCurrent) {
		router.push({ name: 'home' })
	} else {
		router.push({
			name: 'week',
			params: {
				week_number:
					weeksById.value[viewingWeekId.value].week_number.toString(),
			},
		})
	}
}

function reloadEntries() {
	loadUser()
	loadEntries(season.value!.slug, currentWeek.value.id)
}
</script>
