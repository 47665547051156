<template>
	<layout-main :show-header="true">
		<div class="flex mt-6 items-center space-x-4">
			<img
				:src="`https://id.blokpax.com/avatar/@${authStore.user.username}?size=128`"
				:alt="`@${authStore.user.username}`"
				class="w-20 h-20 rounded-full border-4 border-gray-500"
			/>
			<div class="font-bold text-4xl text-gray-800">
				@{{ authStore.user.username }}
			</div>
		</div>

		<div class="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-6">
			<div class="bg-white shadow-lg rounded-lg p-6 text-center">
				<div class="text-gray-500 font-medium">Rank</div>
				<div class="text-xl font-bold text-gray-800">
					{{ currentRank }}
				</div>
			</div>
			<div class="bg-white shadow-lg rounded-lg p-6 text-center">
				<div class="text-gray-500 font-medium">Highest Streak</div>
				<div class="text-xl font-bold text-gray-800">
					{{ accountDetails?.account.streak }}
				</div>
			</div>
			<div class="bg-white shadow-lg rounded-lg p-6 text-center">
				<div class="text-gray-500 font-medium">Miles Won</div>
				<div class="text-xl font-bold text-gray-800">
					{{ accountDetails?.account.milesWon }}
				</div>
			</div>
		</div>

		<div class="mt-4 text-center text-sm text-gray-600">
			* You can claim your earned miles at
			<a
				href="https://blokpax.com/account/drips"
				class="text-blue-600 underline"
				target="_blank"
			>
				https://blokpax.com/account/drips
			</a>
		</div>

		<div class="mt-12">
			<h2 class="text-3xl font-semibold text-gray-900 mb-2">
				Your Top Heroes
			</h2>
			<div class="mb-6 font-medium text-sm">(Win/Pick Rate)</div>

			<div class="relative">
				<button
					@click="scrollLeft"
					class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-2 rounded-full"
				>
					&larr;
				</button>

				<div
					class="flex overflow-x-auto space-x-6 snap-x snap-mandatory scroll-pl-6"
					ref="heroContainer"
				>
					<div
						v-for="hero in topHeroes"
						:key="hero.id"
						class="w-50 p-4 bg-white rounded-lg shadow-md flex-shrink-0 snap-center transition-transform"
					>
						<img
							:src="hero.image_url"
							:alt="hero.hero_name"
							class="w-full h-40 object-cover rounded-md mb-4"
						/>
						<div class="font-medium text-lg text-gray-900">
							{{ hero.hero_name }}
						</div>
						<div class="text-gray-500">
							Win Rate:
							<span class="font-bold text-green-600"
								>{{
									accountDetails?.account.heroWinPickRate[
										hero.hero_name
									].rate
								}}%</span
							>
						</div>
					</div>
				</div>

				<button
					@click="scrollRight"
					class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-2 rounded-full"
				>
					&rarr;
				</button>
			</div>
		</div>
	</layout-main>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, ref, ComputedRef, computed } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import { usePickSixStore } from '@/stores/PickSixStore'
import { Rank, User } from '@/types/User'
import { AccountDetails } from '@/types/AccountDetails'
import { Season, SeasonResponse } from '@/types/Season'
import { Week } from '@/types/Week'

const authStore = useAuthStore()
const pickSixStore = usePickSixStore()
const season = ref<Season | null>(null)
const user = ref<User | null>(null)
const viewingWeekId = ref<string>('')
const accountDetails = ref<AccountDetails | null>(null)

const currentWeekRank: ComputedRef<Rank | undefined> = computed(() => {
	if (!user.value) {
		return undefined
	}

	return user.value.rankings.find((rank: Rank) => {
		return rank.week_id === viewingWeekId.value
	})
})

const heroContainer = ref(null)

const scrollLeft = () => {
	const container = heroContainer.value
	if (container) {
		container.scrollBy({
			left: -container.offsetWidth / 3,
			behavior: 'smooth',
		})
	}
}

const scrollRight = () => {
	const container = heroContainer.value
	if (container) {
		container.scrollBy({
			left: container.offsetWidth / 3,
			behavior: 'smooth',
		})
	}
}

const currentRank: ComputedRef<number | string> = computed(() => {
	return Number(currentWeekRank.value?.rank) || 'Unranked'
})

async function loadUser(): Promise<User | null> {
	return authStore.getAccount().then((u) => {
		if (u) {
			user.value = u
			return u
		}
		return null
	})
}

async function loadDetails(): Promise<AccountDetails | null> {
	return pickSixStore.accountDetails().then((d) => {
		if (d) {
			accountDetails.value = d
			return d
		}
		return null
	})
}

const heroWinPickRate = computed(() => {
	return accountDetails.value
		? accountDetails.value.account.heroWinPickRate
		: {}
})
const topHeroes = computed(() => {
	if (!season.value || !season.value!.weeks!.current) {
		return []
	}

	const heroes = season.value!.weeks!.current!.heroes

	return Object.keys(heroWinPickRate.value)
		.map((heroName) => {
			const heroDetails = heroes.find(
				(hero) => hero.hero_name === heroName,
			)
			if (heroDetails) {
				return {
					...heroDetails,
					winRate: heroWinPickRate[heroName],
				}
			}
			return null
		})
		.filter((hero) => hero !== null)
		.sort((a, b) => b.winRate - a.winRate)
		.slice(0, 10)
})

const currentWeek: ComputedRef<Week | any> = computed(() => {
	if (season.value?.weeks == undefined) {
		return {}
	}

	if (season.value?.weeks.current == undefined) {
		return {}
	}

	const week = Object.values(season.value?.weeks!).find((week: Week) => {
		if (week.id === viewingWeekId.value) {
			return week
		}
	})
	viewingWeekId.value = week ? week.id : season.value?.weeks.current.id
	return week ? week : season.value?.weeks.current
})

onMounted(async () => {
	if (!authStore.authenticated) {
		await authStore.getAccount()
	}
})

onBeforeMount(async () => {
	loadUser()

	pickSixStore
		.currentSeason()
		.then((response: SeasonResponse): Season => {
			if (response.success) {
				season.value = response.season!
				return response.season!
			}
			throw new Error('Failed to load season')
		})
		.then(async (szn: Season): Promise<void> => {
			let week
			if (currentWeek.value.week_number) {
				week = Object.values(szn.weeks!).find((week: Week) => {
					return (
						week.week_number ===
						Number(currentWeek.value.week_numberr)
					)
				})
			}
			if (week) {
				viewingWeekId.value = week.id
			}
			await loadDetails()
		})
})
</script>
